<template>
    <div class="DriversLog" :class="{ 'is-loading': isFetchingData }">
        <Headline>
            <h1>Fahrtenbuch</h1>
            <p class="Description">
                Verwalten Sie hier Ihre Fahrten und erfassen Sie notwendige Informationen für
                steuerliche Zwecke.
            </p>
        </Headline>

        <!-- Overview Cards Section -->
        <div class="OverviewCards">
            <Card :class="{ highlight: unassignedDriversLog.length > 0 }">
                <h4>Offene Einträge</h4>
                <h2>{{ unassignedDriversLog.length }}</h2>
                <div class="info-icon" v-tooltip="'Fahrten ohne hinterlegten Zweck'">
                    <i class="fas fa-info-circle"></i>
                </div>
            </Card>
            <Card>
                <h4>Gesamtkilometer</h4>
                <h2>{{ totalKilometers }}</h2>
                <div class="info-icon" v-tooltip="'Gesamtkilometer im ausgewählten Zeitraum'">
                    <i class="fas fa-info-circle"></i>
                </div>
            </Card>
            <Card>
                <h4>Dienstfahrten</h4>
                <h2>{{ businessTripsCount }}</h2>
                <div class="info-icon" v-tooltip="'Anzahl der dienstlichen Fahrten'">
                    <i class="fas fa-info-circle"></i>
                </div>
            </Card>
            <Card>
                <h4>Privatfahrten</h4>
                <h2>{{ privateTripsCount }}</h2>
                <div class="info-icon" v-tooltip="'Anzahl der privaten Fahrten'">
                    <i class="fas fa-info-circle"></i>
                </div>
            </Card>
        </div>

        <Toolbar class="DriversLog-Toolbar">
            <div class="DateAndFilter">
                <Datepicker @onChange="handleDateChange" :startDate="selectedFrom" monthPicker />
                <div class="FilterOptions">
                    <Dropdown
                        variant="single-column"
                        :items="filterOptions"
                        :selected="selectedFilter"
                        placeholder="Filter"
                        @onItemSelect="handleFilterSelect"
                    />
                </div>
                <Button size="small" @onClick="handleLoadDriversLog" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
            </div>
            <div class="ExportActions">
                <Button variant="outline" size="small" icon="plus" @onClick="handleAddNewEntry">
                    Neue Fahrt
                </Button>
                <Download title="Download CSV" type="csv" @onDownload="handleDownload('csv')" />
            </div>
        </Toolbar>

        <!-- Status message when no entries -->
        <div class="EmptyState" v-if="driversLog.length === 0 && !isFetchingData">
            <i class="fas fa-car-side"></i>
            <h3>Keine Fahrten gefunden</h3>
            <p>Passen Sie den Zeitraum an oder erstellen Sie einen neuen Eintrag.</p>
            <Button variant="primary" size="medium" @onClick="handleAddNewEntry"
                >Neue Fahrt erstellen</Button
            >
        </div>

        <!-- Loading state indicator -->
        <div class="LoadingState" v-if="isFetchingData">
            <div class="Spinner"></div>
            <p>Daten werden geladen...</p>
        </div>

        <DriverLogTable v-else :driversLog="driversLog" @onLogItemClick="handleLogItemClick" />

        <Modal
            title="Fahrtenbucheintrag"
            :action="{ text: 'Speichern', color: 'green', callback: handleAddPurpose }"
            :cancel="{ text: 'Abbrechen' }"
            :show="showPurposeModal"
            :closeOnBackdropClick="false"
            @onModalClose="handleHidePurposeModal"
        >
            <div class="LogEntryForm">
                <div class="FormSection">
                    <h3>Fahrer & Zweck</h3>
                    <div class="InputGroup">
                        <div class="InputWrap">
                            <label for="driver">Fahrer</label>
                            <Dropdown
                                id="driver"
                                hasSearch
                                variant="single-column"
                                :items="drivers.filter(d => d.isVisible)"
                                :selected="selectedLogItem.driverName"
                                :class="{ 'has-error': wrongDriver }"
                                placeholder="Fahrer auswählen"
                                @onItemSelect="handleDriverSelect"
                            />
                            <span class="Hint" :class="{ error: wrongDriver }">
                                Der Fahrer wird für die Auswertung benötigt.
                            </span>
                        </div>
                        <div class="InputWrap">
                            <label for="purpose">Zweck</label>
                            <Dropdown
                                id="purpose"
                                hasSearch
                                variant="single-column"
                                :items="categories.filter(c => c.id !== 'none')"
                                :selected="selectedLogItem.purpose"
                                :class="{ 'has-error': wrongPurpose }"
                                placeholder="Kategorie auswählen"
                                @onItemSelect="handleCategorySelect"
                            />
                            <input
                                v-if="
                                    selectedLogItem.purpose &&
                                        selectedLogItem.purpose.id === 'other'
                                "
                                class="Input CustomCategory"
                                id="other"
                                type="text"
                                :class="{ 'has-error': wrongPurpose }"
                                v-model="selectedLogItem.purpose.value"
                                placeholder="Eigene Kategorie"
                                required
                            />
                            <span class="Hint" :class="{ error: wrongPurpose }">
                                Die Kategorie wird für die Auswertung benötigt.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="FormSection">
                    <h3>Route</h3>
                    <div class="InputGroup RouteGroup">
                        <div class="InputWrap" :class="{ 'has-error': wrongStartDestination }">
                            <label for="from">Von</label>
                            <input
                                class="Input"
                                id="from"
                                v-model="selectedLogItem.from"
                                placeholder="Startort"
                                required
                            />
                            <span v-if="wrongStartDestination" class="Hint error">
                                Bitte geben Sie einen Startort an.
                            </span>
                        </div>
                        <div class="DirectionIcon">
                            <i class="fas fa-arrow-right"></i>
                        </div>
                        <div class="InputWrap" :class="{ 'has-error': wrongEndDestination }">
                            <label for="to">Nach</label>
                            <input
                                class="Input"
                                id="to"
                                v-model="selectedLogItem.to"
                                placeholder="Zielort"
                                required
                            />
                            <span v-if="wrongEndDestination" class="Hint error">
                                Bitte geben Sie einen Zielort an.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="FormSection">
                    <h3>Kilometerstand</h3>
                    <div class="KilometerSlider">
                        <div class="KilometerReadings">
                            <div class="KilometerReading">
                                <span>Start:</span>
                                <strong>{{ selectedLogItem.startKM }} km</strong>
                            </div>
                            <div class="KilometerReading">
                                <span>Ende:</span>
                                <strong>{{ selectedLogItem.endKM }} km</strong>
                            </div>
                            <div class="KilometerDistance">
                                <span>Strecke:</span>
                                <strong
                                    >{{
                                        selectedLogItem.endKM - selectedLogItem.startKM
                                    }}
                                    km</strong
                                >
                            </div>
                        </div>
                        <RangeSlider
                            :min="previousLogItem.startKM"
                            :max="previousLogItem.endKM"
                            :step="1"
                            @update:minValue="updateStartKM"
                            @update:maxValue="updateEndKM"
                        />
                        <div class="ErrorMessages" v-if="wrongStartKM || wrongEndKM">
                            <span class="Hint error" v-if="wrongStartKM">
                                Start-KM muss im gültigen Bereich liegen.
                            </span>
                            <span class="Hint error" v-if="wrongEndKM">
                                End-KM muss im gültigen Bereich liegen.
                            </span>
                        </div>
                    </div>
                </div>

                <div class="FormSection">
                    <h3>Zusatzinformationen</h3>
                    <div class="InputWrap">
                        <label for="notice">Notiz</label>
                        <textarea
                            class="Input"
                            id="notice"
                            v-model="selectedLogItem.notice"
                            placeholder="Zusätzliche Informationen zur Fahrt"
                        ></textarea>
                        <span class="Hint"
                            >Fügen Sie hier eine Beschreibung oder Notizen zur Fahrt hinzu.</span
                        >
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script type="text/javascript">
import axios from 'axios';
import { addDays, endOfMonth, format, startOfMonth } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Button from '@/components/widgets/Button';
import Headline from '@/components/Headline';
import Modal from '@/components/widgets/Modal';
import Datepicker from '@/components/widgets/Datepicker';
import Toolbar from '@/components/Toolbar';
import DriverLogTable from './components/DriverLogTable.vue';
import RangeSlider from '@/components/widgets/RangeSlider.vue';
import Card from '@/components/Card.vue';

export default {
    name: 'DriversLog',
    components: {
        Button,
        Download,
        Headline,
        Dropdown,
        Modal,
        Toolbar,
        Datepicker,
        DriverLogTable,
        RangeSlider,
        Card,
    },
    data: () => ({
        format,
        isFetchingData: false,
        drivers: JSON.parse(localStorage.getItem('drivers')),
        driversLog: [],
        selectedFrom: startOfMonth(new Date()),
        selectedTo: endOfMonth(new Date()),
        showPurposeModal: false,
        categories: [
            { value: 'Privat', id: 'private' },
            { value: 'Einkauf', id: 'shopping' },
            { value: 'Besorgung', id: 'errand' },
            { value: 'Arzt', id: 'doctor' },
            { value: 'Sonstiges', id: 'other' },
            { value: 'Fahrt im Pflichtfahrgebiet', id: 'regular' },
            { value: undefined, id: 'none' },
        ],
        selectedCategory: null,
        selectedLogItem: {
            start: '',
            end: '',
            purpose: null,
            notice: null,
            startKM: 0,
            endKM: 1000,
        },
        previousLogItem: {
            start: '',
            end: '',
            purpose: null,
            notice: null,
            startKM: 0,
            endKM: 1000,
        },
        wrongStartKM: false,
        wrongEndKM: false,
        wrongPurpose: false,
        wrongDriver: false,
        wrongStartDestination: false,
        wrongEndDestination: false,
        filterOptions: [
            { id: 'all', value: 'Alle Fahrten' },
            { id: 'incomplete', value: 'Unvollständige Einträge' },
            { id: 'business', value: 'Dienstfahrten' },
            { id: 'private', value: 'Privatfahrten' },
        ],
        selectedFilter: { id: 'all', value: 'Alle Fahrten' },
    }),

    computed: {
        unassignedDriversLog() {
            return this.driversLog.filter(d => !d.purpose?.value);
        },
        totalKilometers() {
            return this.driversLog
                .reduce((total, trip) => {
                    return total + (trip.endKM - trip.startKM);
                }, 0)
                .toLocaleString('de-DE');
        },

        businessTripsCount() {
            return this.driversLog.filter(
                trip => trip.purpose?.id === 'regular' || trip.purpose?.id === 'errand',
            ).length;
        },

        privateTripsCount() {
            return this.driversLog.filter(
                trip =>
                    trip.purpose?.id === 'private' ||
                    trip.purpose?.id === 'shopping' ||
                    trip.purpose?.id === 'doctor',
            ).length;
        },
    },
    methods: {
        handleFilterSelect({ item }) {
            this.selectedFilter = item;
            this.applyFilters();
        },
        handleDriverSelect({ item }) {
            this.selectedLogItem = { ...this.selectedLogItem, driverName: item };
        },
        handleAddNewEntry() {
            // Create a new empty log entry and open the modal
            this.selectedLogItem = {
                id: uuidv4(),
                from: '',
                to: '',
                purpose: null,
                notice: '',
                startKM:
                    this.driversLog.length > 0 ? Math.max(...this.driversLog.map(d => d.endKM)) : 0,
                endKM:
                    this.driversLog.length > 0
                        ? Math.max(...this.driversLog.map(d => d.endKM)) + 10
                        : 10,
                driverName: null,
            };
            this.previousLogItem = { ...this.selectedLogItem };
            this.handleShowPurposeModal();
        },
        handleLogItemClick(log) {
            log.from = log.from === 'Undefinierte Fahrt' ? '' : log.from;
            log.to = log.to === 'Undefinierte Fahrt' ? '' : log.to;
            log.purpose = log.purpose.id === 'none' ? null : log.purpose;
            this.selectedLogItem = { ...log };
            this.previousLogItem = { ...log };
            this.handleShowPurposeModal();
        },
        handleCategorySelect({ item }) {
            this.selectedLogItem = { ...this.selectedLogItem, purpose: item };
        },
        handleLoadDriversLog() {
            this.isFetchingData = true;
            axios
                .get(`${process.env.VUE_APP_API_BASE_URL}/driversLog`, {
                    params: {
                        startAt: format(this.selectedFrom, 'yyyy-MM-dd'),
                        endAt: format(this.selectedTo, 'yyyy-MM-dd'),
                    },
                    withCredentials: true,
                })
                .then(res => {
                    this.driversLog = res.data;
                })
                .catch(err => {})
                .finally(() => {
                    this.isFetchingData = false;
                });
        },
        handleDateChange({ from, to }) {
            this.selectedFrom = from;
            this.selectedTo = to;
        },
        handleShowPurposeModal() {
            this.showPurposeModal = true;
        },
        handleHidePurposeModal() {
            this.selectedLogItem = {
                start: '',
                end: '',
                purpose: null,
                notice: null,
                startKM: 0,
                endKM: 1000,
            };
            this.showPurposeModal = false;
        },
        handleAddPurpose() {
            const index = this.driversLog.findIndex(item => item.id === this.selectedLogItem.id);
            if (index === -1) return;

            if (!this.selectedLogItem.driverName) {
                this.wrongDriver = true;
                this.$toasted.error('Bitte wählen Sie einen Fahrer aus.');
                return;
            }
            this.wrongDriver = false;
            if (!this.selectedLogItem?.purpose?.value) {
                this.wrongPurpose = true;
                this.$toasted.error('Bitte wählen Sie einen Zweck aus.');
                return;
            }
            this.wrongPurpose = false;
            if (!this.selectedLogItem?.from || !this.selectedLogItem?.to) {
                this.wrongStartDestination = true;
                this.wrongEndDestination = true;
                this.$toasted.error('Bitte geben Sie Von und Nach an.');
                return;
            }
            this.wrongStartDestination = false;
            this.wrongEndDestination = false;
            if (this.selectedLogItem.startKM < this.previousLogItem.startKM) {
                this.wrongStartKM = true;
                this.$toasted.error(
                    'Start-KM muss größer oder gleich dem Start-KM des vorherigen Eintrags sein.',
                );
                return;
            }

            if (this.selectedLogItem.endKM > this.previousLogItem.endKM) {
                this.wrongEndKM = true;
                this.$toasted.error(
                    'End-KM muss kleiner oder gleich dem End-KM des vorherigen Eintrags sein.',
                );
                return;
            }

            if (this.selectedLogItem.startKM > this.previousLogItem.endKM) {
                this.wrongStartKM = true;
                this.$toasted.error(
                    'Start-KM darf nicht größer als das End-KM des vorherigen Eintrags sein.',
                );
                return;
            }

            if (this.selectedLogItem.endKM < this.previousLogItem.startKM) {
                this.wrongEndKM = true;
                this.$toasted.error(
                    'End-KM darf nicht kleiner als das Start-KM des vorherigen Eintrags sein.',
                );
                return;
            }

            this.wrongEndKM = false;
            this.wrongStartKM = false;

            // Send new entry to the backend
            axios
                .post(`${process.env.VUE_APP_API_BASE_URL}/driversLog`, this.selectedLogItem, {
                    withCredentials: true,
                })
                .then(response => {
                    // Update the driversLog with the response data
                    this.driversLog.splice(index, 1, response.data);

                    if (
                        this.selectedLogItem.startKM !== this.previousLogItem.startKM ||
                        this.selectedLogItem.endKM !== this.previousLogItem.endKM
                    ) {
                        this.adjustNeighboringEntries(this.selectedLogItem, this.previousLogItem);
                    }

                    this.handleHidePurposeModal();
                })
                .catch(err => {
                    console.error(err);
                    this.$toasted.error('Eintrag konnte nicht gespeichert werden.');
                });
        },
        adjustNeighboringEntries(logItem, previousLogItem) {
            const indexOfCurrentLogItem = this.driversLog.findIndex(item => item.id === logItem.id);
            if (logItem.startKM !== previousLogItem.startKM) {
                const newEntry = {
                    ...previousLogItem,
                    id: uuidv4(),
                    purpose: this.categories.find(c => c.id === 'none'),
                    from: 'Undefinierte Fahrt',
                    to: 'Undefinierte Fahrt',
                    startKM: previousLogItem.startKM,
                    endKM: logItem.startKM,
                };

                // Send new entry to the backend
                axios
                    .post(`${process.env.VUE_APP_API_BASE_URL}/driversLog`, newEntry, {
                        withCredentials: true,
                    })
                    .then(response => {
                        this.driversLog.splice(indexOfCurrentLogItem, 0, response.data);
                    })
                    .catch(err => {
                        console.error(err);
                        this.$toasted.error('Eintrag konnte nicht gespeichert werden.');
                    });
            }

            if (logItem.endKM !== previousLogItem.endKM) {
                const newEntry = {
                    ...previousLogItem,
                    id: uuidv4(),
                    startKM: logItem.endKM,
                    endKM: previousLogItem.endKM,
                };

                // Send new entry to the backend
                axios
                    .post(`${process.env.VUE_APP_API_BASE_URL}/driversLog`, newEntry, {
                        withCredentials: true,
                    })
                    .then(response => {
                        this.driversLog.splice(indexOfCurrentLogItem + 1, 0, response.data);
                    })
                    .catch(err => {
                        console.error(err);
                        this.$toasted.error('Eintrag konnte nicht gespeichert werden.');
                    });
            }
        },
        handleDeletePurpose() {
            this.isFetchingData = true;
            axios
                .delete(`/api/v1/driversLog/${this.activeEmployee.driverNumber}`, {
                    withCredentials: true,
                })
                .then(res => {
                    this.driversLog = this.driversLog.filter(
                        d => d.driverNumber !== this.activeEmployee.driverNumber,
                    );
                    this.filteredDriversLog = this.filteredDriversLog.filter(
                        d => d.driverNumber !== this.activeEmployee.driverNumber,
                    );
                    this.handleHideDeleteModal();
                })
                .catch(err => {})
                .finally(() => {
                    this.isFetchingData = false;
                });

            this.handleHideDeleteModal();
        },
        createRandomLogbookEntries(startKM, endKM, numEntries) {
            const logbook = [];
            let currentKM = startKM;

            for (let i = 0; i < numEntries; i++) {
                const isRegularTrip = Math.random() > 0.2;
                const tripLength = isRegularTrip
                    ? Math.floor(Math.random() * 150 + 50)
                    : Math.floor(Math.random() * 100 + 10);
                const tripEndKM = Math.min(currentKM + tripLength, endKM);
                const startDate = addDays(new Date(), Math.floor(Math.random() * 30));
                const endDate = addDays(startDate, Math.floor(Math.random() * 30));
                const entry = {
                    startDate,
                    endDate,
                    id: uuidv4(),
                    from: isRegularTrip ? 'Betriebsfahrt start' : 'Undefinierte Fahrt',
                    to: isRegularTrip ? 'Betriebsfahrt ende' : 'Undefinierte Fahrt',
                    startKM: currentKM,
                    endKM: tripEndKM,
                    purpose: isRegularTrip
                        ? this.categories.find(c => c.id === 'regular')
                        : this.categories.find(c => c.id === 'none'),
                };

                logbook.push(entry);
                currentKM = tripEndKM;

                if (currentKM >= endKM) {
                    break;
                }
            }

            return logbook;
        },
        handleDownload(type) {
            const data = this.driversLog.map(d => {
                return {
                    Von: d.from,
                    Nach: d.to,
                    'Start-KM': d.startKM,
                    'End-KM': d.endKM,
                    Zweck: d.purpose.value ? d.purpose.value : 'Kein Zweck hinterlegt',
                };
            });
            data.unshift({
                Von: 'Von',
                Nach: 'Nach',
                'Start-KM': 'Start-KM',
                'End-KM': 'End-KM',
                Zweck: 'Zweck',
            });

            if (type === 'csv') {
                this.downloadCSV(data);
            }
        },
        downloadCSV(data) {
            const csv = data.map(row => Object.values(row).join(',')).join('\n');
            const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            const csvURL = window.URL.createObjectURL(csvData);
            const tempLink = document.createElement('a');

            tempLink.href = csvURL;
            tempLink.setAttribute(
                'download',
                `fahrtenbuch-${format(new Date(), 'yyyy-MM-dd')}.csv`,
            );
            tempLink.click();
            tempLink.remove();
        },
        updateStartKM(value) {
            this.selectedLogItem.startKM = value;
        },
        updateEndKM(value) {
            this.selectedLogItem.endKM = value;
        },
    },
    mounted() {
        this.$emit('onFinishLoading');

        const logbook = this.createRandomLogbookEntries(0, 1000, 100);
        this.driversLog = logbook;
    },
};
</script>

<style lang="scss">
.DriversLog {
    @extend %contentWrapper;
    @extend %page;

    .Modal {
        .Dropdown {
            display: block;
        }
        .Selected {
            width: 100%;
        }
        .Row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            column-gap: 10px;
        }
    }

    .InputWrap {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        label {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .Input,
        textarea {
            padding: 8px;
            border-radius: 5px;
            border: 1px solid var(--color-text-inactive);
            background: var(--color-white);

            &:focus {
                outline: none;
                border-color: color(blue-light);
            }
        }

        textarea {
            min-height: 100px;
        }

        .Hint {
            font-size: 12px;
            color: #595959;
            margin-top: 5px;
        }
    }

    .Search {
        border: solid 1px var(--color-text-inactive);
        border-radius: 4px;
        margin: 5px 10px 20px 0;
    }
}
</style>
