var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DriversLog",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', [_c('h1', [_vm._v("Fahrtenbuch")]), _c('p', {
    staticClass: "Description"
  }, [_vm._v(" Verwalten Sie hier Ihre Fahrten und erfassen Sie notwendige Informationen für steuerliche Zwecke. ")])]), _c('div', {
    staticClass: "OverviewCards"
  }, [_c('Card', {
    class: {
      highlight: _vm.unassignedDriversLog.length > 0
    }
  }, [_c('h4', [_vm._v("Offene Einträge")]), _c('h2', [_vm._v(_vm._s(_vm.unassignedDriversLog.length))]), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Fahrten ohne hinterlegten Zweck',
      expression: "'Fahrten ohne hinterlegten Zweck'"
    }],
    staticClass: "info-icon"
  }, [_c('i', {
    staticClass: "fas fa-info-circle"
  })])]), _c('Card', [_c('h4', [_vm._v("Gesamtkilometer")]), _c('h2', [_vm._v(_vm._s(_vm.totalKilometers))]), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Gesamtkilometer im ausgewählten Zeitraum',
      expression: "'Gesamtkilometer im ausgewählten Zeitraum'"
    }],
    staticClass: "info-icon"
  }, [_c('i', {
    staticClass: "fas fa-info-circle"
  })])]), _c('Card', [_c('h4', [_vm._v("Dienstfahrten")]), _c('h2', [_vm._v(_vm._s(_vm.businessTripsCount))]), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Anzahl der dienstlichen Fahrten',
      expression: "'Anzahl der dienstlichen Fahrten'"
    }],
    staticClass: "info-icon"
  }, [_c('i', {
    staticClass: "fas fa-info-circle"
  })])]), _c('Card', [_c('h4', [_vm._v("Privatfahrten")]), _c('h2', [_vm._v(_vm._s(_vm.privateTripsCount))]), _c('div', {
    directives: [{
      name: "tooltip",
      rawName: "v-tooltip",
      value: 'Anzahl der privaten Fahrten',
      expression: "'Anzahl der privaten Fahrten'"
    }],
    staticClass: "info-icon"
  }, [_c('i', {
    staticClass: "fas fa-info-circle"
  })])])], 1), _c('Toolbar', {
    staticClass: "DriversLog-Toolbar"
  }, [_c('div', {
    staticClass: "DateAndFilter"
  }, [_c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "monthPicker": ""
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('div', {
    staticClass: "FilterOptions"
  }, [_c('Dropdown', {
    attrs: {
      "variant": "single-column",
      "items": _vm.filterOptions,
      "selected": _vm.selectedFilter,
      "placeholder": "Filter"
    },
    on: {
      "onItemSelect": _vm.handleFilterSelect
    }
  })], 1), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleLoadDriversLog
    }
  }, [_vm._v(" Anzeigen ")])], 1), _c('div', {
    staticClass: "ExportActions"
  }, [_c('Button', {
    attrs: {
      "variant": "outline",
      "size": "small",
      "icon": "plus"
    },
    on: {
      "onClick": _vm.handleAddNewEntry
    }
  }, [_vm._v(" Neue Fahrt ")]), _c('Download', {
    attrs: {
      "title": "Download CSV",
      "type": "csv"
    },
    on: {
      "onDownload": function onDownload($event) {
        return _vm.handleDownload('csv');
      }
    }
  })], 1)]), _vm.driversLog.length === 0 && !_vm.isFetchingData ? _c('div', {
    staticClass: "EmptyState"
  }, [_c('i', {
    staticClass: "fas fa-car-side"
  }), _c('h3', [_vm._v("Keine Fahrten gefunden")]), _c('p', [_vm._v("Passen Sie den Zeitraum an oder erstellen Sie einen neuen Eintrag.")]), _c('Button', {
    attrs: {
      "variant": "primary",
      "size": "medium"
    },
    on: {
      "onClick": _vm.handleAddNewEntry
    }
  }, [_vm._v("Neue Fahrt erstellen")])], 1) : _vm._e(), _vm.isFetchingData ? _c('div', {
    staticClass: "LoadingState"
  }, [_c('div', {
    staticClass: "Spinner"
  }), _c('p', [_vm._v("Daten werden geladen...")])]) : _c('DriverLogTable', {
    attrs: {
      "driversLog": _vm.driversLog
    },
    on: {
      "onLogItemClick": _vm.handleLogItemClick
    }
  }), _c('Modal', {
    attrs: {
      "title": "Fahrtenbucheintrag",
      "action": {
        text: 'Speichern',
        color: 'green',
        callback: _vm.handleAddPurpose
      },
      "cancel": {
        text: 'Abbrechen'
      },
      "show": _vm.showPurposeModal,
      "closeOnBackdropClick": false
    },
    on: {
      "onModalClose": _vm.handleHidePurposeModal
    }
  }, [_c('div', {
    staticClass: "LogEntryForm"
  }, [_c('div', {
    staticClass: "FormSection"
  }, [_c('h3', [_vm._v("Fahrer & Zweck")]), _c('div', {
    staticClass: "InputGroup"
  }, [_c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "driver"
    }
  }, [_vm._v("Fahrer")]), _c('Dropdown', {
    class: {
      'has-error': _vm.wrongDriver
    },
    attrs: {
      "id": "driver",
      "hasSearch": "",
      "variant": "single-column",
      "items": _vm.drivers.filter(function (d) {
        return d.isVisible;
      }),
      "selected": _vm.selectedLogItem.driverName,
      "placeholder": "Fahrer auswählen"
    },
    on: {
      "onItemSelect": _vm.handleDriverSelect
    }
  }), _c('span', {
    staticClass: "Hint",
    class: {
      error: _vm.wrongDriver
    }
  }, [_vm._v(" Der Fahrer wird für die Auswertung benötigt. ")])], 1), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "purpose"
    }
  }, [_vm._v("Zweck")]), _c('Dropdown', {
    class: {
      'has-error': _vm.wrongPurpose
    },
    attrs: {
      "id": "purpose",
      "hasSearch": "",
      "variant": "single-column",
      "items": _vm.categories.filter(function (c) {
        return c.id !== 'none';
      }),
      "selected": _vm.selectedLogItem.purpose,
      "placeholder": "Kategorie auswählen"
    },
    on: {
      "onItemSelect": _vm.handleCategorySelect
    }
  }), _vm.selectedLogItem.purpose && _vm.selectedLogItem.purpose.id === 'other' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLogItem.purpose.value,
      expression: "selectedLogItem.purpose.value"
    }],
    staticClass: "Input CustomCategory",
    class: {
      'has-error': _vm.wrongPurpose
    },
    attrs: {
      "id": "other",
      "type": "text",
      "placeholder": "Eigene Kategorie",
      "required": ""
    },
    domProps: {
      "value": _vm.selectedLogItem.purpose.value
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.selectedLogItem.purpose, "value", $event.target.value);
      }
    }
  }) : _vm._e(), _c('span', {
    staticClass: "Hint",
    class: {
      error: _vm.wrongPurpose
    }
  }, [_vm._v(" Die Kategorie wird für die Auswertung benötigt. ")])], 1)])]), _c('div', {
    staticClass: "FormSection"
  }, [_c('h3', [_vm._v("Route")]), _c('div', {
    staticClass: "InputGroup RouteGroup"
  }, [_c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.wrongStartDestination
    }
  }, [_c('label', {
    attrs: {
      "for": "from"
    }
  }, [_vm._v("Von")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLogItem.from,
      expression: "selectedLogItem.from"
    }],
    staticClass: "Input",
    attrs: {
      "id": "from",
      "placeholder": "Startort",
      "required": ""
    },
    domProps: {
      "value": _vm.selectedLogItem.from
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.selectedLogItem, "from", $event.target.value);
      }
    }
  }), _vm.wrongStartDestination ? _c('span', {
    staticClass: "Hint error"
  }, [_vm._v(" Bitte geben Sie einen Startort an. ")]) : _vm._e()]), _c('div', {
    staticClass: "DirectionIcon"
  }, [_c('i', {
    staticClass: "fas fa-arrow-right"
  })]), _c('div', {
    staticClass: "InputWrap",
    class: {
      'has-error': _vm.wrongEndDestination
    }
  }, [_c('label', {
    attrs: {
      "for": "to"
    }
  }, [_vm._v("Nach")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLogItem.to,
      expression: "selectedLogItem.to"
    }],
    staticClass: "Input",
    attrs: {
      "id": "to",
      "placeholder": "Zielort",
      "required": ""
    },
    domProps: {
      "value": _vm.selectedLogItem.to
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.selectedLogItem, "to", $event.target.value);
      }
    }
  }), _vm.wrongEndDestination ? _c('span', {
    staticClass: "Hint error"
  }, [_vm._v(" Bitte geben Sie einen Zielort an. ")]) : _vm._e()])])]), _c('div', {
    staticClass: "FormSection"
  }, [_c('h3', [_vm._v("Kilometerstand")]), _c('div', {
    staticClass: "KilometerSlider"
  }, [_c('div', {
    staticClass: "KilometerReadings"
  }, [_c('div', {
    staticClass: "KilometerReading"
  }, [_c('span', [_vm._v("Start:")]), _c('strong', [_vm._v(_vm._s(_vm.selectedLogItem.startKM) + " km")])]), _c('div', {
    staticClass: "KilometerReading"
  }, [_c('span', [_vm._v("Ende:")]), _c('strong', [_vm._v(_vm._s(_vm.selectedLogItem.endKM) + " km")])]), _c('div', {
    staticClass: "KilometerDistance"
  }, [_c('span', [_vm._v("Strecke:")]), _c('strong', [_vm._v(_vm._s(_vm.selectedLogItem.endKM - _vm.selectedLogItem.startKM) + " km")])])]), _c('RangeSlider', {
    attrs: {
      "min": _vm.previousLogItem.startKM,
      "max": _vm.previousLogItem.endKM,
      "step": 1
    },
    on: {
      "update:minValue": _vm.updateStartKM,
      "update:maxValue": _vm.updateEndKM
    }
  }), _vm.wrongStartKM || _vm.wrongEndKM ? _c('div', {
    staticClass: "ErrorMessages"
  }, [_vm.wrongStartKM ? _c('span', {
    staticClass: "Hint error"
  }, [_vm._v(" Start-KM muss im gültigen Bereich liegen. ")]) : _vm._e(), _vm.wrongEndKM ? _c('span', {
    staticClass: "Hint error"
  }, [_vm._v(" End-KM muss im gültigen Bereich liegen. ")]) : _vm._e()]) : _vm._e()], 1)]), _c('div', {
    staticClass: "FormSection"
  }, [_c('h3', [_vm._v("Zusatzinformationen")]), _c('div', {
    staticClass: "InputWrap"
  }, [_c('label', {
    attrs: {
      "for": "notice"
    }
  }, [_vm._v("Notiz")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedLogItem.notice,
      expression: "selectedLogItem.notice"
    }],
    staticClass: "Input",
    attrs: {
      "id": "notice",
      "placeholder": "Zusätzliche Informationen zur Fahrt"
    },
    domProps: {
      "value": _vm.selectedLogItem.notice
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.selectedLogItem, "notice", $event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "Hint"
  }, [_vm._v("Fügen Sie hier eine Beschreibung oder Notizen zur Fahrt hinzu.")])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }